import { useEffect, useState } from 'react';
import './roadmap.scss'
type Loading = {
    setIsLoading(flag:boolean):void;
};
export default function RoadMap({setIsLoading}:Loading) {
    const [loadedImgCount, setLoadedImgCount] = useState(0)
    const onLoad =()=>{
        setLoadedImgCount(loadedImgCount + 1)
    }
    useEffect(() => {
        if (loadedImgCount >= 2){
            setIsLoading(false)
        }
        
    }, [loadedImgCount, setIsLoading]);
    return (
        <div className="roadmap" id ="roadmap">
            <div className="roadmapTitle">
                <h1>Road Map</h1>
            </div>
            <div className="roadmapContent">
                    <div className="node">
                        <div className="nodeLeft"  data-aos="fade-right">
                            <img src="assets/roadmap_03.png" alt="" onLoad={onLoad}/>
                        </div>
                        <div className="nodeRight" data-aos="fade-left">
                            <h2>Land Clearing</h2>
                            <div className="nodeContent first" >
                                <h3 className='firstSub'>Phase 1 (4/1/22-6/30/22)</h3>
                                <p className='firstTxt1'>○	Launch with an ultra low circulating supply of 1,000. </p>
                                <p className='firstTxt2'>○	Whitepaper and roadmap is published on <a href="www.incomenfts.xyz" target='_blank'rel="noreferrer">www.incomenfts.xyz</a> . Twitter and Discord community is launched.</p>
                                <p className='firstTxt3'>○	Subject to listing fees, the NFTs will be tradeable on the <a href="https://www.jpg.store/" target='_blank'rel="noreferrer">https://www.jpg.store/</a> NFT marketplace or an alternative.</p>
                                <p>○	These proceeds will fund the development of the next NFT project</p>

                            </div>
                            <div className="nodeContent second">
                                <h3>Phase 2 (7/1/22-9/30/22)</h3>
                                <p>○	Launch iNFTs Land Clearing NFT #2 with a circulating supply of TBD. </p>
                                <p>○	9/30/22 1st Quarterly Payout for Phase 1</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="node mobNode">
                        <div className="nodeLeft" data-aos="fade-right">
                        <div className="nodeContent">
                                <h3>Phase 3 (10/1/22-12/31/22)</h3>
                                <p className='firstTxt5'>○	Launch iNFTs Land Clearing NFT #3 with a circulating supply of TBD. </p>
                                <p className='firstTxt6'>○	12/25/22 10% of the proceeds will be put into a buy back program. given to <a href="https://families4families.cc/" target='_blank'rel="noreferrer">https://families4families.cc/</a> hopefully before Christmas.</p>
                                <p className='firstTxt7'>○	12/31/22 Quarterly Payout for Phase 1 & 2</p>
                            </div>
                        </div>

                        <div className="nodeRight" data-aos="fade-left">
                                <img src="assets/roadmap_02.png" alt=""  onLoad={onLoad}/>
                        </div>
                    </div>
                </div>

        </div>
    )
}
