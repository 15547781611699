import MintCountModal from 'components/mintCountModal/MintCountModal';
import MintModal from 'components/mintModal/MintModal';
import { useState } from 'react';
import Timer from '../timer/Timer';
import './mint.scss'

type Loading = {
    setIsLoading(flag:boolean):void;
};

export default function Mint({setIsLoading}:Loading) {
    const [mintModalShow, setMintModalShow] = useState(false);
    const [mintHowMany, setMintHowMany] = useState(false);
    const [count, setCount] = useState(1);
    const [showMint, setShowMint] = useState(false)
    const onLoad =()=>{
        setIsLoading(false)
    }
    return (
        <div className="mint" id="mint">
            <div className="mintContent">
                <div className="mintLeft"  data-aos="fade-right">
                    <img src="assets/mint_img.png" alt="" onLoad={onLoad} />
                </div>
                <div className="mintRight"  data-aos="fade-left">
                    <h1 className="mintTitle">Mint Annihilator</h1>
                    <div className="mintDate">
                        <p>Annihilators are invading the Cardano blockchain on </p>
                        <p><strong>April 1 at 12:00 UTC</strong></p>
                    </div>
                    <div className="mintText">
                        <p><strong>Send 525 ADA per Annihilator</strong></p>
                        <p>Up to 5 per transaction. Unlimited Transactions. 1.5 ADA will be returned per Annihilator minted.</p>
                    </div>
                    {showMint ? 
                    <Timer setShowMint = {setShowMint} deadLine = {1648814400}/>:
                    <div className="mintButton" onClick={() => { setMintHowMany(true) }}>
                        <span>Mint</span>
                    </div>
                    }
                    
                </div>
            </div>
            <MintCountModal
                mintCount = {count}
                setMintCount = {setCount}
                showCountModal = {mintHowMany}
                setShowCountModal = {setMintHowMany}
                setShowMintModal = {setMintModalShow}
            />
            <MintModal 
                mintCount = {count}
                showMintModal = {mintModalShow}
                setShowCountModal = {setMintHowMany}
                setShowMintModal= {setMintModalShow}
            />
        </div>
    )
}

