import { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import './mintCountModal.scss'

interface Props {
    mintCount:number,
    setMintCount?:any,
    showCountModal: boolean,
    setShowCountModal?: any
    setShowMintModal?: any
}

export default function MintCountModal(
    {
        mintCount, 
        setMintCount, 
        showCountModal, 
        setShowCountModal,
        setShowMintModal
    }:Props) {

    const onMint = () => {
        setShowCountModal(false)
        setShowMintModal(true)
    }
    const onClose = () => {
        setShowCountModal(false)
    }
    const handleCountChange = (value: any) => {
        setMintCount(value)
    }
    const [isAgreed, setIsAgreed] = useState(false)
    return (

        <div className={showCountModal? "mintCountModal active": "mintCountModal"}>
            
            <div className="modalContent" >
                <div className="mintTop">
                    <img className="logoIcon" src="assets/mainLogo.png" alt="" />
                    <button onClick={onClose}>Return Home <i className="fas fa-angle-double-right"></i></button>
                </div>
                
                <div className="modalWrapper">
                    <div className="left">
                        <img src="assets/preview-1.gif" alt="" />

                    </div>
                    <div className="right">
                    <h1>How many Annihilators are you minting?</h1>
                    <div className="mintRadio">
                    <input type="radio" value='1' checked={mintCount === 1} onClick={() => { handleCountChange(1) }} onChange = {()=>{}}/> <span>1</span>
                    <input type="radio" value='2' checked={mintCount === 2} onClick={() => { handleCountChange(2) }} onChange = {()=>{}} /> <span>2</span>
                    <input type="radio" value='3' checked={mintCount === 3} onClick={() => { handleCountChange(3) }} onChange = {()=>{}} /> <span>3</span>
                    <input type="radio" value='5' checked={mintCount === 5} onClick={() => { handleCountChange(5) }} onChange = {()=>{}} /> <span>5</span>
                    </div>
                    <p>You will be purchasing <strong>{mintCount}</strong> </p>
                    <button  onClick={()=>{onMint()}}  disabled = {!isAgreed} >Mint <strong>{mintCount}</strong> {mintCount===1 ?'Annihilator':'Annihilators' }</button>
                    
                    </div>
                    
                </div>
                <span>
                        <input type="checkbox" value='checked' checked={isAgreed} onClick={() => {setIsAgreed(!isAgreed)}} onChange = {()=>{}}/>
                        
                             I agree to the 
                             <HashLink to="/terms_condition" smooth > Terms & Conditions</HashLink>
                             &nbsp;and &nbsp;
                             <HashLink to="/privacy_policy" smooth >Privacy Policy.</HashLink> 
                        </span>
            </div>

        </div>
    )
}

