import './terms.scss'
export default function Terms() {
    return (
        <div className="terms" id="terms">
            <div className="termsContent">
                <h1>iNFT TERMS OF SERVICE</h1>
                <p>Date of Initial Publication: 31 mar 2022</p>
                <p>PLEASE FIND BELOW THE TERMS AND CONDITIONS GOVERNING THE iNFT OFFERINGS.</p>
                <h2>1. Definitions</h2>
                <ul>
                    <li>Artwork: Refers to the drawing and the graphic content owned by the Company and licensed to </li>
                    <li>Purchaser under this Contract.</li>
                    <li>Contract: Refers to these T&Cs.</li>
                    <li>Company: Refers to Betonyou as defined in the Legal Notice above. Parties: Refers to the Purchaser and the Company and “Party” refers to one of them.</li>
                    <li>Purchaser: Refers to any person who subscribes to the NFTs’ Sale on the Website.</li>
                    <li>Terms and Conditions (“T&Cs”): Refers to this contract, which constitutes a legally binding agreement between the Purchaser and the Company, governing the purchase of NFTs in the context of the Sale.</li>
                    <li>NFTs: Refers to the non-fungible tokens issued by the Company which represents Artwork based on the Betonyou universe. Sale: Refers to the issuance of NFTs by the Company from the Website.</li>
                    <li>You: Refers to you as the current Purchaser of the Sale and Party of these Terms and Conditions.</li>
                    <li>Website: Refers to all content and services of the Company accessible from the incomenfts.xyz domain, its linked domain and subdomains.</li>

                </ul>
                <h2>2. Acceptance</h2>
                <p>Acceptance. By participating in the Sale, You accept and agree to these Terms and Conditions without any condition or restriction.</p>
                <p>If You do not agree to this Contract, You shall not participate in the Sale made by the Company.</p>
                <p>Capacity: You confirm that you have the legal capacity to receive and hold and make use of the NFT under French jurisdiction and any other relevant jurisdiction.</p>

                <h2>3. Purpose</h2>
                <p>These Terms and Conditions are related to the sale of NFTs by the Company (the “Company”) on its Website. It solely governs the contractual relationship between You and the Company regarding the Sale and any related contract.</p>

                <h2>4. The Sale</h2>
                <p>The Company offers NFTs featuring the iNFTs for real estate. The holders of one or more iNFTs is liable to success and create successful businesses either for startups or existing businesses.</p> 
                <p>To release the iNFTs and fund the project, the Company offers NFTs from a dedicated website("Sale"). The web address of this website will be given at the time of the mint. The NFT acquisition does not confer any rights on the Company or in the future development.</p>
                <p>Price: The NFT’s price is determined at the moment of Your purchase. You expressly agree that the price is subject to changes.</p>
                <p>By participating in the purchase of NFT, the buyer confirms that he/she is in compliance with the regulations of his/her country For further information on the Sale, please consult the Company’s Website or contact the Company.</p>

                <h2>5. Warnings</h2>
                <p>Participating in the Sale is a risky operation, and therefore it is only aimed at experienced purchasers who are used to blockchain technology and NFTs.</p>

                <p>These Terms of Service (“Terms”) govern your use of the services provided by iNFT. (“we”, “us”, “our”, or “Company”), including without limitation our website or other digital products that link to or reference these Terms (collectively, the “Services”). These Terms are a binding legal agreement between you or the entity you represent (“you”) and Company. In these Terms, “you” and “your” refer to you, a user of the Services. A “user” is you or anyone who accesses, browses, or in any way uses the Services.</p>
                <p>These Terms refer to our <a href="/privacy_policy" target='_blank'rel="noreferrer">Privacy Policy.</a> (“Privacy Policy”), hereby incorporated by reference, which also applies to your use of the Services and which sets out the terms on which we process any personal data we collect from you, or that you provide to us. </p>

                <p>Though your access and use of the Services is governed by the Terms effective at the time, please note that we may revise and update these Terms from time to time at our discretion. If we make material changes to these Terms, a notice will be on our website prior to the effective date of the changes. These Terms are to ensure that you will use the Services only in the ways in which we intend for it to be used.</p>
                <p>By accessing or using the Services:</p>
                <ul>
                    <li>You acknowledge that you have read, understood, and accept these Terms and any additional documents or policies referred to in or incorporated into these Terms, whether you are participating as a guest or as a registered user; </li>
                    <li>If these Terms have materially changed since you last accessed or used the Services, you acknowledge and agree that your continued access or use of the Services constitutes your acceptance of the changed Terms;</li>
                    <li>You represent and warrant that you are at least eighteen (18) years of age and have the right, authority, and capacity to enter into these Terms, either on behalf of yourself or the entity that you represent.</li>
                </ul>


                <p>By accepting this Contract, You acknowledge (i) having the necessary knowledge and understanding of the blockchain technology and the NFTs, and (ii) understand the risks associated with blockchain-based software systems and NFTs as described hereof and in the current Contract.</p>
                <p>You may not infringe the reputation of the Company or any of its interests.</p>

                <h2>6. Company’s liability</h2>
                <p>Company undertakes to act with the care normally expected from a professional in his field and to comply with the best practice in force. The best endeavor obligation only binds the Company.</p>
                <p>Limitation: Subject to any applicable contrary law, You may only incur the Company's liability if You prove that (i) the Company acted wrongfully and intentionally and (ii) exclusively if this behavior caused You a specific, personal and direct damage.</p>
                <p>Exclusion: Subject to any applicable contrary law, the Company should not be liable for any direct or indirect damage resulting from:</p>
                <ul>
                    <li>THE PARTIAL OR TOTAL LOSS OF THE VALUE OF NFTS;</li>
                    <li>ANY BREACH OF THIS CONTRACT BY THE PURCHASER OR A THIRD PARTY CONTROLLED BY THE PURCHASER;</li>
                    <li>LOSS OF CONTROL FOR ANY REASON WHATSOEVER (LOSS, HACKING, TECHNICAL FAILURE, ETC.) OF THE PURCHASER’S WALLET</li>
                    <li>TEMPORARY OR PERMANENT SUSPENSION OF THE PROJECT, FOR ANY REASON WHATSOEVER, INCLUDING DUE TO A REQUEST FROM THE PUBLIC AUTHORITIES, JUDICIAL AUTHORITY OR ANY THIRD PARTY;</li>
                    <li>ANY FAILURE OF THE COMPUTER AND SOFTWARE USED BY THE PURCHASER;</li>
                    <li>ANY DAMAGE THAT OCCURRED BEYOND THE CONTROL OF COMPANY: INTERNET NETWORK, FAILURE SPECIFIC TO THE PURCHASER’S EQUIPMENT, ETC.;</li>
                    <li>ANY RISK DESCRIBED IN THE PRESENT CONTRACT.</li>
                </ul>
                <h2>7. IP Rights</h2>
                <p>The Company owns and retains all intellectual property rights, interests, and titles to the full extent and without limitation on the Website, the Project, the NFTs and all elements.</p>
                <p>You acknowledge without limitation that all intellectual property rights mentioned on the Website are the sole property of their respective owners. References to any product, platform, third-party service do not constitute recommendations or endorsements from the Company regarding them.</p>

                <h2>8.Contact</h2>
                <p>If You have any questions regarding this Contract, please contact the Company at the following address Contact @incomenfts.xyz or visit the “Contact us” section on the Website.</p>

            </div>
        </div>
    )
}
