import './about.scss'
export default function About() {
    return (
        <div className="about" id="about">
            <div className="aboutContent">
                <div className="left"  data-aos="fade-right">
                    <h1>About</h1>
                    <p>Welcome to iNFTs. Founded in 2022, iNFT’s is pioneering a new and exciting way to give investors the opportunity to get in on start-ups, or already established businesses.</p> 
                    <p>iNFTs has carefully selected and researched 3 separate Phases for 3 Pilot programs. We picked these to make sure we provide three healthy products from each business that can support the income loss. The first of these we will bring to market is iNFTs Land Clearing.</p> 
                    <p>These NFT's unlike most others, have a percentage of the profit from the machine tied to each one. Investors will get paid out at a quarterly rate based on profits of that machine.</p> 
                    <p>From there investors are free to compound their earnings at the current market rate, or they can sell at any point in the market.</p> 
                    <p>The investors can hold on to their NFT and they will continue to collect profit percentages for the lifetime of this machine.</p> 
                    <p>At the End of Phase 3, we will move forward with our buyback program! 10% of the proceeds from the NFTs sale and Royalties will be spent on repurchasing our NFTs from holders if they are willing to sell.</p> 
                    <p>We will buy them back on the open market for up to 25% above the original floor price.</p> <p>We then plan on partnering with organizations like <a href="https://families4families.cc/">Family 4 Families</a> to make sure the NFTs get to those in need.</p> 
                    <p>Please see White paper for more details.</p>
                    <a href="https://families4families.cc/" target='_blank'rel="noreferrer" className='family'><img src="assets/family.png" alt="" /></a>
                </div>
                <div className="right"  data-aos="fade-left">
                    <img src="assets/welocme_bg.png" alt="" />
                </div>
                {/* <h1>Welcome</h1>
                    <p>Welcome to iNFTs. Founded in 2022, iNFT’s is pioneering a new and exciting way to give investors the opportunity to get in on start-ups, or already established businesses.</p> 
                    <p>iNFTs has carefully selected and researched 3 separate Phases for 3 Pilot programs. We picked these to make sure we provide three healthy products from each business that can support the income loss. The first of these we will bring to market is iNFTs Land Clearing.</p> 
                    <p>These NFT's unlike most others, have a percentage of the profit from the machine tied to each one. Investors will get paid out at a quarterly rate based on profits of that machine.</p> 
                    <p>From there investors are free to compound their earnings at the current market rate, or they can sell at any point in the market.</p> 
                    <p>The investors can hold on to their NFT and they will continue to collect profit percentages for the lifetime of this machine.</p> 
                    <p>At the End of Phase 3, we will move forward with our buyback program! 10% of the proceeds from the NFTs sale and Royalties will be spent on repurchasing our NFTs from holders if they are willing to sell.</p> 
                    <p>We will buy them back on the open market for up to 25% above the original floor price.</p> <p>We then plan on partnering with organizations like Family 4 Families to make sure the NFTs get to those in need.</p> 
                    <p>Please see White paper for more details.</p> */}
            </div>
            {/* <img src="assets/welocme_bg.png" alt="" /> */}
        </div>
    )
}
