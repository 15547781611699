import Footer from 'components/footer/Footer';
import Policy from 'components/policy/Policy';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Menu from '../../components/menu/Menu';
import Topbar from '../../components/topbar/Topbar';
import './policyPage.scss'

export default function PolicyPage() {
    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "screen and (max-width: 768px) and (orientation:portrait)", });
    const isLandOrMobile = useMediaQuery({ query: "screen and (max-height: 768px) and (orientation:landscape)", });
    useEffect(() => {
        if (isTabletOrMobile) {
        }

        if (isLandOrMobile) {
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
        
    }, [isTabletOrMobile, isLandOrMobile]);
    return (
        <div className="policyPage">
            <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <div className="sections">
                <Policy/>
            </div>
            <Footer/>
        </div>
    )
}
