import './policy.scss'
export default function Policy() {
    return (
        <div className="policy" id="policy">
            <div className="policyContent">
            <h1>Privacy Policy</h1>
            <h2>Introduction</h2>
            <p></p>
            <p>Protecting your private information is our priority.This Statement of Privacy applies to the Site and and iNFTs and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to iNFTs include the Site and iNFTs arts.The iNFTs website is provided to house the iNFTs arts for business to have access to. By using the iNFTs website, you consent to the data practices described in this statement.</p>

            <h2>Collection of your Personal Information</h2>
                    <p>When connected and purchased any of the iNFTs Art, we collect the transaction information. This information is used to identify which particular wallet made the purchase of a particular iNFTs art. iNFTs encourages you to review the privacy statements of website and community  so that you can understand how we collect, use and share your information. iNFTs is not responsible for the privacy statements or other content on websites and communities outside of the iNFTs website.</p>

                    <h2>Use of your Personal Information</h2>
                    <p>iNFTs collects and uses your personal information to operate its website and deliver the services you have requested. iNFTs may also use your personally identifiable information to inform you of other products or services available from iNFTs. iNFTs may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered. iNFTs does not sell, rent or lease its customer lists to third parties. iNFTs may share data with trusted parties to help perform statistical analysis, send you email or postal mail or provide customer support. All such third parties are prohibited from using your personal information except to provide these services to iNFTs and they are required to maintain the confidentiality of your information. iNFTs will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on NFT Art Generator; (b) protect and defend the rights or property of NFT Art Generator; and, (c) act under exigent circumstances to protect the personal safety of users of NFT Art Generator, or the public.</p>

                    <h2>Automatically Collected Information</h2>
                    <p>Information about your computer hardware and software may be automatically collected. This information can include: your IP address, browser type , domain names, access times and referring website addresses.This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the iNFT Art website.</p>
                    <h2>Security of your Personal Information</h2>
                    <p>iNFTs secures your personal information from unauthorized access, use, or disclosure. iNFTs use the SSL Protocol for this purpose: When personal information is transmitted to other websites, it is protected through the use of encryption - the Secure Sockets Layer (SSL) protocol.</p>

                    <h2>Children under Thirteen</h2>
                    <p>NFT Art Generator does not knowingly collect personally identifiable information from children under the age of thirteen.</p>

                    <h2>Changes to this Statement</h2>
                    <p>iNFTs will occasionally update this Statement of Privacy to reflect company and customer feedback. INFTs encourages you to periodically review this Statement to be informed of how NFT Art Generator is protecting your information.</p>

                    <h2>Contact Information</h2>
                    <p>iNFTs welcomes your questions or comments regarding this Statement of Privacy.If you believe that iNFTs has not adhered to this Statement, please contact us by sending a message through the website.</p>

            </div>
        </div>
    )
}
