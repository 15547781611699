import './app.scss';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from 'pages/HomePage/HomePage';
import TermsPage from 'pages/termsPage/TermsPage';
import PolicyPage from 'pages/policyPage/PolicyPage';
function App() {


  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/terms_condition" component={TermsPage} />
        <Route exact path="/privacy_policy" component={PolicyPage} />
      </Switch>
    </Router>
  );
}

export default App;
