import './topbar.scss'
import { HashLink } from 'react-router-hash-link'
type MenuType = {
    menuOpen? : boolean;
    setMenuOpen(flag:boolean):void;
};
export default function Topbar({menuOpen, setMenuOpen}:MenuType) {
    return (
        <div className="topbar">
            <div className="logo">
                <HashLink to="/#home" smooth ><img src="assets/mainLogo.png" alt="" /></HashLink>
                
            </div>
            <div className="navList">
                <ul>
                    <li><HashLink to="/#home" smooth >Home</HashLink></li>
                    <li><HashLink to="/#about" smooth>About</HashLink></li>
                    <li><HashLink to="/#mint" smooth>Mint</HashLink></li>
                    <li><HashLink to="/#roadmap" smooth>RoadMap</HashLink></li>
                </ul>
            </div>
            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick = {()=> setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            
        </div>
    )
}
