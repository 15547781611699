import { useRef } from 'react';
import './mintModal.scss'
interface Props {
    mintCount :number,
    showMintModal: boolean,
    setShowCountModal?: any
    setShowMintModal?: any
}

export default function MintModal(
    {
        mintCount, 
        showMintModal, 
        setShowCountModal, 
        setShowMintModal
    }:Props) {
    const inputEl = useRef<HTMLInputElement>(null);
    const clickHandle = () => {
        if (inputEl && inputEl.current) {
            inputEl.current.select();
            document.execCommand('copy');
        }
    }
    const onCloseToHome = () => {
        setShowMintModal(false)
    }
    const onCloseToMintCount = () => {
        setShowCountModal(true)
        setShowMintModal(false)
    }
    
    return (
        <div  className={showMintModal? "mintModal active": "mintModal"} >
            
            <div className="modalContent" >
                <div className="mintTop">
                    <button onClick={onCloseToMintCount}><i className="fas fa-angle-double-left"></i> Return To Mint Count</button>
                    <button onClick={onCloseToHome}>Return To Home <i className="fas fa-angle-double-right"></i></button>
                </div>
                <div className="modalWrapper">
                    <h1>{mintCount===1 ?'Your Annihilator is on its way':'Your Annihilators are on their way' }</h1>
                    <div className="address">
                        <p>Price : {mintCount * 525}ADA</p>
                        <input className="addressInput" ref={inputEl} type="text" value='addr1v8pcend68ua0x9g8gwj3ectx8v3klsfj5lz8agw5jjservqvz905l' onChange={() => { }} />
                        <div className="state">
                            <span className="statetext">The Annihilator Status : Reserved</span>
                            <div className="rect"></div>
                        </div>
                        <button className="coppyAddressButton" onClick={clickHandle}>Copy Address</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

