import "./countDown.scss"
import { useState, useEffect } from 'react';

type TimeNumber = {
    deadLine:number,
    setShowMint(value: boolean): void
};
export default function Timer({ setShowMint, deadLine }: TimeNumber) {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {

        let myInterval = setInterval(() => {
            const currentDate: any = Date.now()/1000;
            const diff = deadLine - currentDate;
            const dayNum = diff > 0 ? Math.floor(diff  / 60 / 60 / 24) : 0;
            const hourNum = diff > 0 ? Math.floor(diff  / 60 / 60) % 24 : 0;
            const minNum = diff > 0 ? Math.floor(diff  / 60) % 60 : 0;
            const secNum = diff > 0 ? Math.floor(diff ) % 60 : 0;

            if (currentDate < deadLine) {
                setDays(dayNum);
                setHours(hourNum);
                setMinutes(minNum);
                setSeconds(secNum);
            }
            else{
                setShowMint(true)
            }

        }, 0)
        return () => {
            clearInterval(myInterval);
        };

    }, [deadLine, setShowMint]);

    return (
        <div className="timer">
            {days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0
                ? null
                : <div className="timerNums">
                    <div className="days">
                        <div className="number">
                            <span>{Math.floor(days/10)} <div className="bottom"></div></span>
                            <span>{days%10}<div className="bottom"></div></span>
                        </div>
                        <div className="text">Days</div>
                    </div>
                    <div className="hours">
                        <div className="number">
                            <span>{Math.floor(hours/10)}<div className="bottom"></div></span>
                            <span>{hours%10}<div className="bottom"></div></span>
                        </div>
                        <div className="text">Hrs</div>
                    </div>
                    <div className="mins">
                        <div className="number">
                            <span>{Math.floor(minutes/10)}<div className="bottom"></div></span>
                            <span>{minutes%10}<div className="bottom"></div></span>
                        </div>
                        <div className="text">Mins</div>
                    </div>
                    <div className="seconds">
                        <div className="number">
                            <span>{Math.floor(seconds/10)}<div className="bottom"></div></span>
                            <span>{seconds%10}<div className="bottom"></div></span>
                        </div>
                        <div className="text">Secs</div>
                    </div>
                </div>
            }
        </div>
    )
}