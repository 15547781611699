import './home.scss'
type Loading = {
    setIsLoading(flag:boolean):void;
};

export default function Home({setIsLoading}:Loading) {
    const onLoad =()=>{
        setIsLoading(false)
    }
    return (
        <div className="home" id = "home">
            
            <div className="homeContent" >
                <div className="homeWrapper">
                    <div className="homeLeft"  data-aos="fade-right">
                        <h1>Welcome to the Launch of <span>The Annihilator</span> </h1>
                        <p>Join our Discord and follow our Twitter for giveaways, NFT/Crypto discussions and more.</p>
                        <div className="buttons">
                            <a href="https://discord.gg/9RCAaPfcBB" target='_blank' rel="noreferrer" className='discord'><i className="fab fa-discord"></i> Join Discord</a>
                            <a href="https://twitter.com/IncomeNFTs?t=_twGzoj4x620s21OS27Grw&s=09" target='_blank'rel="noreferrer" className='twitter'><i className="fab fa-twitter"></i>Twitter</a>
                        </div>
                    </div>
                </div>
                <img src="assets/home_bg.jpg" alt="" className="bg"  onLoad={onLoad}/>
            </div>
            
        </div>
    )
}
