import './footer.scss'

export default function Footer() {
    return (
        <div className="footer">
            <div className="footerContent">
                <div className="socialLinks">
                    <div className="socialIcon"><a href="https://discord.gg/9RCAaPfcBB" target='_blank' rel="noreferrer"><i className="fab fa-discord"></i></a></div>
                    <div className="socialIcon"><a href="https://twitter.com/IncomeNFTs?t=_twGzoj4x620s21OS27Grw&s=09" target='_blank'rel="noreferrer"><i className="fab fa-twitter"></i></a></div>
                    <div className="socialIcon"><a href="https://linktr.ee/incomenfts" target='_blank'rel="noreferrer"><img src="assets/linktree.png" alt="" /></a></div>
                    
                </div>
                <p>Copyright © 2021, All rights reserved.</p>
            </div>
        </div>
    )
}
